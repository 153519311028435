import type { DataTableNS, StrategyNS } from '@/typings'
import shopee from '@/assets/platforms/shopee.svg'
import lazada from '@/assets/platforms/lazada.svg'
import vn from '@/assets/flags/round/vn.svg'
import my from '@/assets/flags/round/my.svg'
import id from '@/assets/flags/round/id.svg'
import th from '@/assets/flags/round/th.svg'
import ph from '@/assets/flags/round/ph.svg'
import sg from '@/assets/flags/round/sg.svg'

export const SORT_VALUE: StrategyNS.DefaultComponentProps = {
  descending: 'desc',
  ascending: 'asc',
  desc: 'descending',
  asc: 'ascending',
  descend: 'descending',
  ascend: 'ascending',
}

export enum DateFormat {
  DEFAULT = 'YYYY-MM-DD',
  DEFAULT_DATETIME = 'YYYY-MM-DD HH:mm:ss',
  DATE_HOUR_MINUTE = 'YYYY-MM-DD HH:mm',
  DEFAULT_TIME = 'HH:mm:ss',
  HOUR_MINUTE = 'HH:mm',
  CUSTOM_DATE_HOUR_MINUTE = 'YYYY-MM-DD, HH:mm',
  MONTH_DATE = 'MM-DD',
}

export enum PLATFORM {
  SHOPEE = 'shopee',
  LAZADA = 'lazada',
}
export const ListDatePickerType: string[] = [
  'date',
  'datetime',
  'daterange',
  'datetimerange',
  'month',
  'year',
  'quarter',
  'monthrange',
  'quarterrange',
  'yearrange',
]

export const PLATFORMS_FLAG: StrategyNS.DefaultComponentProps = {
  [PLATFORM.SHOPEE]: shopee,
  [PLATFORM.LAZADA]: lazada,
}

export const COUNTRY_FLAG: StrategyNS.DefaultComponentProps = {
  vn,
  my,
  id,
  ph,
  th,
  sg,
}

export const REGEX_NUMBER = /[^\d.]+/g
export const REGEX_FORMAT_NUMBER = /\B(?=(\d{3})+(?!\d))/g
export const REGEX_NUMBER_TWO = /\b([1-9]\d{2,}|[2-9]\d+)\b/g
export const REGEX_STRING = /\$\s?/g
export const REGEX_TAB = /\t/g
export const REGEX_PERIOD_DAYS = /^([0]|9[1-9]|[1-9][0-9][0-9]+)$/g

export const TIMEZONE_COUNTRY: Record<string, string> = {
  vn: 'Asia/Ho_Chi_Minh',
  my: 'Asia/Kuala_Lumpur',
  id: 'Asia/Jakarta',
  ph: 'Asia/Manila',
  th: 'Asia/Bangkok',
  sg: 'Asia/Singapore',
}

export const DatatableInjectKey = 'datatable_inject'

export const DEFAULT_PAGE = 1
export const DEFAULT_PAGE_SIZE = 20
export const DataTableParams: DataTableNS.DataTableParams = {
  page: DEFAULT_PAGE,
  pageSize: DEFAULT_PAGE_SIZE,
  sort: {
    prop: null,
    order: null,
  },
  filters: {},
}
export const DEFAULT_ROUTE_QUERY: string[] = [
  'page',
  'pageSize',
  'sortField',
  'sortDirection',
]
export const DEFAULT_EXECUTION_LOG_ROUTE_QUERY: string[] = [
  'page',
  'pageSize',
  'sortField',
  'sortDirection',
  'status',
]
export enum FREQUENCY_TYPE {
  MINUTELY = 'minutely',
  HOURLY = 'hourly',
  DAILY = 'daily',
  WEEKLY = 'weekly',
}
export enum WEEK_FORMAT {
  DEFAULT = 'dddd',
  SHORT = 'ddd',
  MIN = 'dd',
}
export enum OPERATORS {
  BETWEEN = 'between',
  IN = 'in',
  NOT_IN = 'not_in',
  CONTAINS = 'contains',
  EXCLUDES = 'excludes',
}
export enum COMPONENT_TYPE {
  TEXT = 'text',
  STRING = 'string',
  DECIMAL = 'decimal',
  INTEGER = 'integer',
  DATETIME = 'datetime',
  DATE = 'date',
  DROPDOWN = 'dropdown',
  BETWEEN = 'between',
  CHECKED_DROPDOWN = 'checked_dropdown',
  TAGS = 'tags',
  MULTIPLE_DATE = 'multiple_date',
  AUTOCOMPLETE = 'autocomplete',
  READONLY = 'readonly',
  DROPDOWN_INPUT = 'dropdown_input',
}
export enum STORE_CHANNELS {
  LAZADA_SPONSORED_DISCOVERY = 'lazada_sponsored_discovery',
  SHOPEE_PRODUCT_ADS = 'shopee_product_ads',
  SHOPEE_SHOP_ADS = 'shopee_shop_ads',
}
export const TOOLTIP_PROPS = {
  width: 300,
}
export enum USER_STATUS {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  PENDING = 'pending',
}
export enum USER_PARTNER_TYPE {
  INTERNAL = 'internal',
  SAAS = 'saas',
}
export const LIST_OPERATOR: Record<string, string> = {
  Eq: '=',
  Ne: '!=',
  Ge: '>=',
  Le: '<=',
  Gt: '>',
  Lt: '<',
  In: 'in',
  NotIn: 'not_in',
  Between: 'between',
  Contain: 'contain',
  Exclude: 'exclude',
  Contains: 'contains',
  Excludes: 'excludes',
}
export const SUPPORT_OPERATOR = [
  'Ge',
  'Le',
  'Gt',
  'Lt',
  'Eq',
  'Ne',
  'Contain',
  'Exclude',
  'Between',
  'In',
  'NotIn',
  'Contains',
  'Excludes',
]
export const CHANNEL_TABNAME: Record<string, string> = {
  lazada_sponsored_discovery: 'sponsored-discovery',
  shopee_product_ads: 'product-ads',
  shopee_shop_ads: 'shop-ads',
}
export enum CAMPAIGN_TAB_NAME {
  SPONSORED_DISCOVERY = 'sponsored-discovery',
  PRODCT_ADS = 'product-ads',
  SHOP_ADS = 'shop-ads',
  KEYWORDS = 'keywords',
}
