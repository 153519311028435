/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
import Axios from 'axios'
import type { AxiosResponse, InternalAxiosRequestConfig } from 'axios'
import { ElMessage } from 'element-plus'
import type { AppAxiosInstance, ResponseBody } from '@/typings/api'
import { API_BASE_URL, ID_TOKEN_DEV, TOKEN } from '@/configs/common'
import i18n from '@/plugins/i18n'
import { ERROR_CODES, ERROR_STATUS } from './error-code'
import { logout } from '@/utils/auth0'
import 'element-plus/theme-chalk/el-message.css'
import router from '@/router'
import { getCache } from '@/utils/cache'
import useTranslation from '@/hooks/translation'

const { transGroup } = useTranslation()

const axios = Axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'content-type': 'application/json;charset=utf-8',
  },
  timeout: 60 * 1000,
}) as AppAxiosInstance

axios.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    const token = getCache(TOKEN)

    // const token = import.meta.env.DEV
    //   ? ID_TOKEN_DEV
    //   : useAuth0Store().getToken()
    if (config.headers) {
      config.headers.Authorization = token || ''
    }
    return config
  },
  err => Promise.reject(err),
)

export function promptError(msg: string) {
  const { t } = i18n.global as any
  const message = t(msg)
  ElMessage.error(message)
  return message
}

async function handleResponse(response: AxiosResponse<ResponseBody>) {
  if (!response) return Promise.reject(new Error('CANCELED'))

  if (response.status !== 200) {
    const path = ERROR_STATUS[response.status] || ERROR_STATUS[500]
    const message = promptError(`error.status.${path}`)

    return Promise.reject(new Error(message))
  }

  if (response?.data?.code !== '0') {
    const code = response?.data?.code?.slice(2) ?? ''
    const path = ERROR_CODES[code]

    const message =
      path &&
      code !== 'A0007' &&
      code !== 'A0601' &&
      code !== 'B0001' &&
      code !== 'A0004'
        ? promptError(`errors.code.${path}`)
        : response?.data?.msg
    if (code === 'A0007') {
      logout()
    } else if (code === 'A0601') {
      // NOTE: check this function checkUserStatus
      logout()
    } else if (code === 'A0004') {
      promptError(transGroup('errors', 'status.NOT_FOUND'))
      setTimeout(() => {
        router.push({ path: '/' })
      }, 2000)
    } else if (code === 'B0210') {
      return Promise.resolve(response.data)
    }
    return Promise.reject(
      new Error(message, {
        cause: code,
      }),
    )
  }

  return Promise.resolve(response.data.data)
}

axios.interceptors.response.use(
  async response => handleResponse(response),
  (error: Error & { response: AxiosResponse<any> }) =>
    handleResponse(error.response),
)

export default axios
