import {
  cloneDeep,
  countBy,
  forIn,
  get,
  includes,
  merge,
  set,
  size,
  sum,
} from 'lodash-es'
import type { DataTableFilterState } from 'naive-ui'
import { defineStore } from 'pinia'
import { DataTable } from '@/typings'
import type { DataTableNS } from '@/typings'
import { DataTableParams } from '@/constants/common'
import { mergeParams } from '@/utils/util'
import { METRIC_ENUMS } from '@/constants/campaign'
import { CAM_ADVANCED_FIELDS } from '@/constants/advanced'

export const useDataTableStore = defineStore(DataTable.name, {
  state: (): DataTable.State => ({
    params: cloneDeep(DataTableParams),
    routeParams: {},
    cacheFilters: {
      options: {},
      customs: {},
    },
  }),
  actions: {
    mergeParams(data: DataTableNS.DataTableParams) {
      this.params = mergeParams(this.params, data)
      return this.params
    },
    getQueryParams(data?: Partial<DataTableNS.DataTableParams>, configs?: any) {
      const dataParams = this.mergeParams(
        (data ?? {}) as DataTableNS.DataTableParams,
      )
      const { sort, filters, ...resultParams } = dataParams
      if (configs) {
        forIn(configs, (fieldName: string, key: any) => {
          const filterValue = get<any>(filters, key)
          if (filterValue) {
            set(filters, fieldName, filterValue.flat(1))
            delete filters[key]
          }
        })
      }
      resultParams.sortField = sort?.prop
      resultParams.sortDirection = sort?.order
      resultParams.filters = []
      forIn(filters, (value: any, field: any) => {
        if (size(value) > 0) {
          if (Array.isArray(value)) resultParams.filters.push({ field, value })
          else resultParams.filters.push({ field, ...value })
        }
      })
      return resultParams as DataTableNS.DataTableQueryParams
    },
    setDataParams(
      page: number,
      pageSize: number,
      sort?: DataTableNS.DataTableSortState,
      filters?: DataTableFilterState,
      params?: any,
    ) {
      this.mergeParams({
        page,
        pageSize,
        sort: sort as DataTableNS.DataTableSortState,
        filters: filters as DataTableFilterState,
      })
      this.routeParams = mergeParams(this.routeParams, filters)
      if (params) {
        this.mergeParams(params)
      }
      return this.params
    },
    applyFilters(filters: any) {
      this.params.filters = merge(this.params.filters, filters)
    },
    applyFilter(key: string, value: any) {
      set(this.params.filters, key, value)
    },
    showAllFilters(params?: any) {
      return (
        sum(
          Object.keys(countBy(merge(this.params.filters, params ?? {}), size)),
        ) > 0
      )
    },
    clearAllFilters() {
      forIn(this.params.filters, (val: any, key: string) => {
        set(this.params.filters, key, [])
      })
      forIn(this.routeParams, (val: any, key: string) => {
        set(this.routeParams, key, [])
      })
    },
    clearFilterByKey(key: string) {
      if (key in this.params.filters) {
        delete this.params.filters[key]
      }
      if (key in this.routeParams) {
        delete this.routeParams[key]
      }
      if (key in this.cacheFilters.customs) {
        delete this.cacheFilters.customs[key]
      }
    },
    setDefaultDataParams() {
      this.params = cloneDeep(DataTableParams)
    },
    getRouteQueryParams(params: DataTableNS.DataTableParams, configs?: any[]) {
      const { sort, filters, ...resultParams } = cloneDeep(params)
      if (configs) {
        forIn(configs, (fieldName: string, key: any) => {
          const filterValue = get<any>(filters, key)
          if (filterValue) {
            set(filters, fieldName, filterValue.flat(1))
          }
        })
      }
      resultParams.sortField = sort?.prop
      resultParams.sortDirection = sort?.order
      return merge(resultParams, filters)
    },
    applyRouteParams(filters: any) {
      this.clearRouteParams()
      this.routeParams = { ...filters }
    },
    clearRouteParams() {
      this.routeParams = {}
    },
    mergeRouteParams(filters: any) {
      this.routeParams = merge(this.routeParams, filters)
      return this.routeParams
    },
    saveOptionFilters(filters: any) {
      this.cacheFilters.options = mergeParams(
        this.cacheFilters.options,
        filters,
      )
    },
    saveCustomFilters(filters: any) {
      this.cacheFilters.customs = mergeParams(
        this.cacheFilters.customs,
        filters,
      )
    },
    clearCacheFilters() {
      this.cacheFilters.customs = {}
      this.cacheFilters.options = {}
    },
    mergeAdvanceRouteParams(filters: any) {
      const lstAdvenced = [
        Object.values(METRIC_ENUMS),
        Object.values(CAM_ADVANCED_FIELDS),
      ].flat()
      forIn(this.routeParams, (val: any, key: string) => {
        if (includes(lstAdvenced, key)) {
          set(this.routeParams, key, filters[key] ?? [])
        }
      })
    },
  },
  getters: {
    getDataParams(state) {
      return state.params
    },
    getRouteParams(state) {
      return state.routeParams
    },
  },
})
