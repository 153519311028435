import { defineStore } from 'pinia'
import { find, sortBy, unionBy } from 'lodash-es'
import { Campaign, DateNS, MetricNS } from '@/typings'
import { dateConfig } from '@/configs/date'
import { format2T8 } from '@/utils/day'

export const useCampaignStore = defineStore(Campaign.name, {
  state: (): Campaign.State => ({
    topFilters: {
      platform: MetricNS.PLATFORM.tmall,
      channel: MetricNS.CHANNEL.onebpSearch,
      dates: {
        mode: DateNS.DYNAMIC_DATE.today,
        customerTimestamp: [],
      },
      store: null,
    },
    config: {
      authorizedStores: [],
      metricConfigs: [],
    },
    tmallStores: [],
    userStores: {
      rows: [],
      total: 0,
    },
    campaignList: {
      rows: [],
      total: 0,
      lastUpdatedAt: '',
    },
    informationTargets: {
      totalDailyBudget: 0,
      limit: 0,
      isOverSpending: false,
      isNoLimit: false,
      isDisabled: false,
    },
    listTag: [],
  }),
  getters: {
    filterDatesIsToday(state) {
      const today = format2T8(dateConfig.T, 'YYYY-MM-DD')
      const { customerTimestamp } = state.topFilters.dates
      return (
        customerTimestamp.length > 0 &&
        customerTimestamp.every(
          stamp => today === format2T8(stamp, 'YYYY-MM-DD'),
        )
      )
    },
    requestDates(state) {
      return state.topFilters.dates.customerTimestamp.length > 0
        ? `[${state.topFilters.dates.customerTimestamp
            .map(stamp => format2T8(stamp))
            .join(',')}]`
        : ''
    },
    requestStoreSession(state) {
      return state.topFilters.store?.sessionKey || ''
    },
    requestPlatform(state) {
      return state.topFilters.platform
    },
    requestChannel(state) {
      return state.topFilters.channel
    },
    requestScene(state) {
      return state.topFilters.channel
    },
    perfomanceMetrics(state) {
      return state.config.metricConfigs.filter(
        item => item.module === 'performance',
      )
    },
    perfomanceMetricsInDefault() {
      return (
        (this as any).perfomanceMetrics as MetricNS.MetricConfig[]
      ).filter(item =>
        [
          MetricNS.SELECT_TYPE.required,
          MetricNS.SELECT_TYPE.optionalAndDefault,
        ].includes(item.selectType),
      )
    },
    findUserStoreByID(state) {
      return (strId: any) => find(state.userStores.rows, ['id', strId])
    },
  },
  actions: {
    setDataUserStores(data: any) {
      this.tmallStores = data?.rows?.filter((item: any) => !!item.sessionKey)
      if (data?.rows) {
        this.userStores.rows = unionBy(this.userStores.rows, data.rows, 'id')
        this.userStores.rows = sortBy(this.userStores.rows, 'name')
        this.userStores.total = data.total
      }
    },
    setCampaignDataList(data: any) {
      this.campaignList = data
    },
    setCampaignTargetLimit(data: any) {
      this.informationTargets = data
    },
    setDataListTag(data: any) {
      this.listTag = data.rows
    },
  },
})
